import { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { theme } from "../../assets/theme";
import { useUserContext } from "../../contexts/user-context";
import { getStats } from "../../services/insights-service";
import {
  IconPathLookupType,
  IInsights,
  InsightByStandard,
} from "../../types/insights";
import { QuickLinks } from "../../components/QuickLinks";
import { InsightsStats } from "../../components/InsightsStats";
import { Benchmarking } from "../../components/Benchmarking";
import { StandardsGraph } from "../../components/StandardsGraph";
import { useStandards } from "../../contexts/StandardsProvider";
import { IconPathType } from "../../types/standard";
import { MaturityGraph } from "../../components/MaturityGraph";
import { AppError } from "../../types/app";
import { useError } from "../../contexts/ErrorContext";

type ColorsMapType = {
  [key in IconPathType]: string;
};

const colorsMap: ColorsMapType = {
  transparentPay: "#D3FF53",
  hiring: "#FF8960",
  performanceManagement: "#BAA3FF",
  flexibleWork: "#8AACD4",
  policies: "#E67A94",
  respectWork: "#8DDDFF",
  leadership: "#94D36D",
  sltExec: "#FF9900",
  parentalLeave: "#8EE4DA",
  cultureValues: "#FDE722",
};

export default function Insights() {
  const { handleError } = useError();
  const userContext = useUserContext();
  const { submittedStandards } = useStandards();
  const iconPaths: IconPathLookupType = {};
  submittedStandards.forEach((eachStandard) => {
    iconPaths[eachStandard.id] = eachStandard.iconPath;
  });

  const [insights, setInsights] = useState<IInsights | undefined>(undefined);
  const [standardInsights, setStandardInsights] = useState<InsightByStandard[]>(
    [],
  );
  const [iconPathLookup, setIconPathLookup] =
    useState<IconPathLookupType>(iconPaths);
  const [average, setAverage] = useState<number>(0);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getInsights = async () => {
    try {
      let totalScore = 0;
      const stats = await getStats();
      const insightsByStandard: InsightByStandard[] = Object.keys(
        stats.byStandard,
      ).map((eachKey) => {
        const eachStandard = stats.byStandard[eachKey];
        const totalActionItems = eachStandard.todo + eachStandard.done;
        const score = parseInt(
          (
            ((totalActionItems - eachStandard.todo) / totalActionItems) *
            100
          ).toFixed(2),
        );
        totalScore += score;
        return {
          id: parseInt(eachKey),
          name: eachStandard.name.replace(/Management/g, "Mgmt."),
          color: iconPathLookup
            ? colorsMap[iconPathLookup[parseInt(eachKey)]]
            : "#D3FF53",
          score: score,
          stats: {
            done: eachStandard.done,
            total: totalActionItems,
          },
        };
      });
      setInsights(stats);
      setAverage(totalScore / Object.keys(stats.byStandard).length);
      setStandardInsights(insightsByStandard);
    } catch (e) {
      const error = e as AppError;
      if (error && error.message) {
        handleError(error.message);
      }
    }
  };
  useEffect(() => {
    const iconPaths: IconPathLookupType = {};
    submittedStandards.forEach((eachStandard) => {
      iconPaths[eachStandard.id] = eachStandard.iconPath;
    });
    setIconPathLookup(iconPaths);
  }, [submittedStandards]);

  useEffect(() => {
    getInsights();
    userContext.set({
      selectedStandard: undefined,
    });
    // eslint-disable-next-line
  }, [iconPathLookup]);

  return (
    <Box
      component="main"
      sx={{ flex: 1, m: isMobile ? "15px 10px 30px 10px" : 5 }}
    >
      <InsightsStats actionItemStats={insights?.total} />
      <MaturityGraph />
      <Benchmarking />
      <StandardsGraph
        standardInsights={standardInsights}
        average={average}
        iconPathLookup={iconPathLookup}
      />
      <QuickLinks />
    </Box>
  );
}
