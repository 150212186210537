import { ApiClient } from "./api-client";
import { AppException } from "../exceptions/exceptions";
import { CommonErrorCodes } from "../errors/common-error-codes";
import { IInsights } from "../types/insights";

export const getStats = async (): Promise<IInsights> => {
  const client = ApiClient.getInstance();
  try {
    const response = await client.get("/data/actionstats?groupBy=standard");
    return response.data as IInsights;
  } catch (e) {
    console.error("An error happened", e);
    throw new AppException(
      "There was an error fetching the insights. Please try again later.",
      CommonErrorCodes.API_ERROR,
    );
  }
};
