import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { useStandards } from "../../contexts/StandardsProvider";
import { Stats } from "../../types/insights";

type InsightsStatsProps = {
  actionItemStats: Stats | undefined;
};

export const InsightsStats = ({
  actionItemStats,
}: InsightsStatsProps): React.ReactElement => {
  const { submittedStandards, unsubmittedStandards } = useStandards();
  const completion =
    actionItemStats && actionItemStats.done !== 0
      ? parseInt(
          (
            (actionItemStats.done /
              (actionItemStats.todo + actionItemStats.done)) *
            100
          ).toFixed(2),
        )
      : 0;
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        lg={2}
        xl={1.5}
        sx={{ paddingBottom: 2 }}
      >
        <Card className="insight-card flexHalf marR20">
          <CardContent sx={{ padding: 0 }}>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              className="insight-card-heading"
            >
              Assessment Completion
            </Typography>
            <Typography variant="h4" className="insight-card-value">
              {submittedStandards.length}/
              {submittedStandards.length + unsubmittedStandards.length}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={9}
        lg={10}
        xl={10.5}
        sx={{ paddingBottom: 2 }}
      >
        <Card className="insight-card flex3">
          <CardContent sx={{ padding: 0 }} className="insight-stat">
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              className="insight-card-heading"
              sx={{
                marginBottom: 0,
              }}
            >
              Action Completion Status
            </Typography>
            <Grid sx={{ display: "flex" }}>
              <Box className="insight-card-container borderRight">
                <Typography
                  gutterBottom
                  variant="body1"
                  component="div"
                  color="text.secondary"
                  className="insight-card-secondary-heading"
                >
                  Not Done
                </Typography>
                <Typography variant="h4" className="insight-card-value">
                  {actionItemStats ? actionItemStats.todo : "-"}
                </Typography>
              </Box>
              <Box className="insight-card-container borderRight">
                <Typography
                  gutterBottom
                  variant="body1"
                  component="div"
                  color="text.secondary"
                  className="insight-card-secondary-heading"
                >
                  Marked Done
                </Typography>
                <Typography variant="h4" className="insight-card-value">
                  {actionItemStats ? actionItemStats.done : "-"}
                </Typography>
              </Box>
              <Box className="insight-card-container">
                <Typography
                  gutterBottom
                  variant="body1"
                  component="div"
                  color="text.secondary"
                  className="insight-card-secondary-heading"
                >
                  Overall Completion
                </Typography>
                <Typography variant="h4" className="insight-card-value">
                  {completion + "%"}
                </Typography>
              </Box>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
