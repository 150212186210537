import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import {
  hiringOutlineLargeIcon,
  performanceManagementOutlineLargeIcon,
  cultureValuesOutlineLargeIcon,
  leadershipOutlineLargeIcon,
  sltExecOutlineLargeIcon,
  flexibleWorkOutlineLargeIcon,
  parentalLeaveOutlineLargeIcon,
  transparentPayOutlineLargeIcon,
  policiesOutlineLargeIcon,
  respectWorkOutlineLargeIcon,
} from "../../assets/icons";
import {
  hiring,
  performanceManagement,
  cultureValues,
  leadership,
  sltExec,
  flexibleWork,
  parentalLeave,
  transparentPay,
  policies,
  respectWork,
} from "../../assets/images/action-pages/index";
import { CircularProgress } from "../../components/CircleProgressBar";
import { IconPathType, IStandards } from "../../types/standard";
import { LevelScore } from "../../types/action-item";
import { InfoTooltip } from "../InfoTooltip";
import { verifyActionItems } from "../../services/standards-service";
import { AppError } from "../../types/app";
import { useError } from "../../contexts/ErrorContext";
import date from "date-and-time";

type MapIconsType = {
  [key in IconPathType]: string[];
};

const mapIcons: MapIconsType = {
  hiring: [hiringOutlineLargeIcon, hiring],
  performanceManagement: [
    performanceManagementOutlineLargeIcon,
    performanceManagement,
  ],
  cultureValues: [cultureValuesOutlineLargeIcon, cultureValues],
  leadership: [leadershipOutlineLargeIcon, leadership],
  sltExec: [sltExecOutlineLargeIcon, sltExec],
  flexibleWork: [flexibleWorkOutlineLargeIcon, flexibleWork],
  parentalLeave: [parentalLeaveOutlineLargeIcon, parentalLeave],
  transparentPay: [transparentPayOutlineLargeIcon, transparentPay],
  policies: [policiesOutlineLargeIcon, policies],
  respectWork: [respectWorkOutlineLargeIcon, respectWork],
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: theme.spacing(1),
  flex: 1,
  border: "none",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

type ActionPlanHeaderProps = {
  standard: IStandards | undefined;
  levelsScore: LevelScore;
  getStandardScore(): void;
};

export const ActionPlanHeader = ({
  standard,
  levelsScore,
  getStandardScore,
}: ActionPlanHeaderProps) => {
  const { handleError } = useError();

  const verifyStandard = async () => {
    try {
      if (standard) {
        await verifyActionItems(standard.id);
        await getStandardScore();
      }
    } catch (e) {
      const error = e as AppError;
      if (error && error.message) {
        handleError(error.message);
      }
    }
  };

  return standard ? (
    <Box>
      <Box
        sx={{
          backgroundColor: "#FF8960",
          height: { xs: "auto", sm: "auto", md: "350px" },
          backgroundImage: `url(${mapIcons[standard.iconPath][1]})`,
          backgroundSize: "100%",
          padding: {
            xs: "15px 20px 15px 10px",
            sm: "15px 20px 15px 10px",
            md: "20px",
          },
        }}
      >
        <Stack
          direction={{ xs: "column", sm: "column", md: "row" }}
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
          sx={{
            background: "#fff",
            padding: { xs: "10px", sm: "10px", md: "22px 22px 10px 22px" },
            borderRadius: "24px",
            border: "1px solid #F3F4F6",
          }}
        >
          <Item
            sx={{
              padding: "15px",
              color: "#000",
            }}
          >
            <Box
              sx={{
                marginBottom: "5px",
              }}
            >
              <Box
                component="img"
                alt={standard.title + " icon"}
                src={mapIcons[standard.iconPath][0]}
              />
              <Typography
                variant="h1"
                sx={{
                  display: "inline-block",
                  marginLeft: "10px",
                  fontSize: "36px",
                  verticalAlign: "bottom",
                  paddingBottom: "4px",
                }}
              >
                {standard.title}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              sx={{
                fontSize: "20px",
              }}
            >
              {standard.description}
            </Typography>
            <Button
              role="button"
              variant="contained"
              className={`standards-btn ${standard.verificationPending ? "disabled" : ""}`}
              sx={{
                margin: "10px 0px",
              }}
              onClick={() => verifyStandard()}
              disabled={standard.verificationPending}
            >
              {standard.verificationPending
                ? "Verification in Progress"
                : "Request Verification "}
            </Button>
            {standard.verificationPending ? (
              <Typography
                sx={{
                  color: "#9CA3AF",
                  marginTop: 2,
                }}
              >
                Pending validation
                <InfoTooltip title="We have your supporting documentation and will email you when validation is complete" />
              </Typography>
            ) : (
              <></>
            )}
            {standard.lastVerifiedAt ? (
              <Typography
                sx={{
                  color: "#9CA3AF",
                  marginTop: 2,
                }}
              >
                Last verified:{" "}
                {date.format(new Date(standard.lastVerifiedAt), "DD MMMM YYYY")}
                <InfoTooltip title="The date your scores were last confirmed by having supporting documentation verified" />
              </Typography>
            ) : (
              <></>
            )}
          </Item>
          <Item
            sx={{
              padding: "15px",
              color: "#000",
              display: { xs: "block", sm: "block", md: "flex" },
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "20px",
                }}
              >
                Marked Done
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontSize: "44px",
                }}
              >
                {(standard.computedScore * 100).toFixed(2) + "% *"}
              </Typography>
              <List
                sx={{
                  marginLeft: "13px",
                }}
              >
                <ListItem disablePadding>
                  <ListItemText
                    primary="Leading"
                    className="level-label leading"
                  />
                  <ListItemText
                    primary={
                      levelsScore.Leading
                        ? (
                            (levelsScore.Leading.completed /
                              levelsScore.Leading.total) *
                            100
                          ).toFixed(2) + `%`
                        : "0%"
                    }
                    sx={{
                      textAlign: "right",
                    }}
                  />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemText
                    primary="Advanced"
                    className="level-label advanced"
                  />
                  <ListItemText
                    primary={
                      levelsScore.Advanced
                        ? (
                            (levelsScore.Advanced.completed /
                              levelsScore.Advanced.total) *
                            100
                          ).toFixed(2) + `%`
                        : "0%"
                    }
                    sx={{
                      textAlign: "right",
                    }}
                  />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemText
                    primary="Foundation"
                    className="level-label foundation"
                  />
                  <ListItemText
                    primary={
                      levelsScore.Foundation
                        ? (
                            (levelsScore.Foundation.completed /
                              levelsScore.Foundation.total) *
                            100
                          ).toFixed(2) + `%`
                        : "0%"
                    }
                    sx={{
                      textAlign: "right",
                    }}
                  />
                </ListItem>
              </List>
              <Typography
                variant="body1"
                sx={{
                  marginTop: "10px",
                  color: "#565A60",
                }}
              >
                *this score is indicative
                <InfoTooltip title="Score is confirmed once supporting documentation has been verified" />
              </Typography>
            </Box>
            <Box sx={{ flex: 1 }}>
              <CircularProgress
                foundationScore={levelsScore.Foundation}
                advancedScore={levelsScore.Advanced}
                leadingScore={levelsScore.Leading}
              />
            </Box>
          </Item>
        </Stack>
      </Box>
    </Box>
  ) : null;
};
